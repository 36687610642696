.color-line{
  width: 80%;
  /* border: 1px solid pink; */
  height: 102px;
  font-size: 18px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 22px;

}

.color-line .label{
  width: 444px;

}
.color-line .row{
  height: 100%;
  flex-grow: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

}


.color-line .color-box{
  width: 18%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* flex-grow: 1; */
}

.color-box .label{
  width: auto;
  text-align: center;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-line input{
  width: 100%;
  height: calc(100% - 56px);
}