.head{
  width: 100%;
  max-height: 100px;
  min-height: 100px;
  background-color: ghostwhite;
  border-bottom: 3px solid grey;


  display: flex;
  justify-content: space-between;
  align-items: center;
  
  color: black;
}

.head-box{
  width: 89px;
}
