.basic-body{
  background-color: rgb(22,22,22);
  width: 100%;
  height: 100%;
  color: white;
  /* border: 3px solid green; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.basic-body .header{
  width: 100%;
  height: 120px;
  text-align: center;
  font-size: 44px;
  font-weight: 800;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.basic-body .body{
  width: 100%;
  flex-grow: 1;
  /* border: 4px solid red; */

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

}

.basic-body .line{
  width: 80%;
  /* border: 1px solid pink; */
  height: 52px;
  font-size: 18px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 22px;
}

.basic-body .line label{
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.basic-body .line .label{
  width: 444px;
}
.basic-body .line .input{
  width: 80%;
}
.basic-body .line .select{
  flex-grow: 1;
  height: calc(100% - 20px);
  font-size: 18px;
}