.printPress{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-family: Bookman;
  background-color: rgb(24,24,24);
  color: white;
}

.printPress .header{
  width: 100%;
  height: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.printPress .body{
  width: 100%;
  flex-grow: 1;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.printPress .left{
  min-width: 800px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.printPress .right{
  /* width: 200px; */
  flex-grow: 1;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.printPress .title{
  font-size: 36px;
  font-weight: 900;
}


.printPress .buttons{
  display: flex;
  width: 500px;
  justify-content: space-between;

  margin-bottom: 63px;
}

.printPress .buttons .btn{

  /* background-color: rgba(0,0,0,0.2); */
  background-color: white;
  color: black;
  padding: 6px;
  border-radius: 5px;
  
}

.printPress .buttons .btn:hover{

  /* background-color: rgba(0,0,0,0.4); */
  background-color: grey;
  cursor: pointer;
}


.printPress .box{
  width: calc(100% - 100px);
  height: 600px;
  
  background-color: white;
  border-radius: 14px 14px 14px 14px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.printHead{
  width: 100%;
  height: 60px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 30px;

}

.printHead .btn{
  background-color: rgb(0,0,0,0.5);
  border-radius: 14px 14px 0px 0px;
  height: 100%;
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.printHead .btn:hover{
  background-color: rgb(0,0,0,0.8);
  cursor: pointer;
}

.printHead .activeBtn{
  background-color: white;
  color: black;
  border-radius: 14px 14px 0px 0px;
  height: 100%;
  flex-grow: 1;
}

.printBody{
  width: 100%;
  flex-grow: 1;
  background-color: white;
  color: black;
  border-radius: 0px 0px 14px 14px;
  font-size: 22px;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
}

.printBody label{
  width: calc(100% - 20px);
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.printBody label .label{
  width: 130px;
}
.printBody label .select{
  flex-grow: 1;
  height: calc(100% - 18px);
  font-size: 33px;
}



.printBody .options{
  width: 100%;
  max-height: calc(60%);
  flex-grow: 1;
  
  border-top: 4px solid rgba(0,0,0,0.3); 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
}