.viewport-container {
  width: 100%;
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  /* border: 1px solid red; */

}

.viewport {
  /* background-color: rgb(11,11,11); */
  background-color: black;
  width: calc(100% - 8px);
  flex-grow: 1;
  max-height: 70%;
  /* height: ; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  border: 1.5px solid white;
  margin: 4px;
  margin-bottom: 8px;

  overflow: hidden;

}