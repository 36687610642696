.dash{
  background-color: rgb(180,180,180);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.dash .title{
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 44px;
  font-weight: 900;
  text-transform: uppercase;
}


.dash .board{
  flex-grow: 1;
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
}


.dash .board .box{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border: 4.4px solid black;
  width: 160px;
  height: 160px;

  border-radius: 8px;
  padding-top: 16px;
  padding-bottom: 6px;

}


.dash .board .box .btn{
  width: 80px;
  height: 80px; 
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.dash .board .box .btn .svg{
  width: 100%;
  height: 100%;
  stroke: black;
  fill: rgba(0,0,0,0);
  stroke-width: 1.75px;
}

.dash .board .box .words{
  font-size: 26px;
  color: black;
  font-weight: 600;
}