.optionsContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.optionsContainer .body{

  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 48px;
  padding-bottom: 10%;
}


.optionsContainer .buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 200px);

}


.optionsContainer .btn{
  width: 500px;
  height: 300px;
  background-color: rgb(16,16,16);
  border: 3px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 18px;
  color: white;
}
.optionsContainer .btn:hover{
  background-color: rgb(0,0,0);
}